import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 开发环境
    // baseURL: 'http://localhost:7777/'
    // 生产环境
    // baseURL: 'http://api.metabyte.top:7777/'
    baseURL: 'http://api.metabyte.top/'
  }
})
